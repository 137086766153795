$sm : 576px;

.order_details {
    background-color: var(--color-white);
    padding: 2rem;
    border-radius: var(--radius);
    box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15);
    max-width: 100%;
    font-size: 1.4rem;
}

.order_details_top {
    margin: 20px 0;
}

.order_details_bottom {
    display: flex;
    gap: 10px;

    @media(max-width: $sm) {
        flex-wrap: wrap;
    }
}

.order_details_bottom_left,
.order_details_bottom_right {
    flex: 1;
}

.customerInfo {
    border-radius: var(--radius);
}

.customerInfo,
.orderSteps,
.orderTimeline,
.orderItems {
    border: 1px solid var(--color-grey-light-4);
    margin-bottom: 10px;
    border-radius: var(--radius);
}

.customerInfo_titleContainer,
.orderTimeline_titleContainer,
.orderItems_titleContainer {
    background-color: var(--color-light);
    border-radius: var(--radius);
}

.customerInfo_title,
.orderTimeline_title,
.orderItems_title {
    padding: 10px;
    font-size: 1.4rem;
    color: var(--color-blue);
}

.customerInfo_detailsContainer,
.orderItems_detailsContainer {
    padding: 10px;

    p {
        margin: 0;
    }
}

.orderSteps {
    padding: 10px;
}

.orderTimeline_detailsContainer {
    padding: 20px 10px 5px 10px;

    @media(max-width: $sm) {
        overflow-x: scroll;
    }
}

.select_order_items {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order_status_select {
    text-transform: uppercase;
    width: 150px;
}

.table {
    margin-top: 1rem;



    tr {
        padding: 7px;

        &::nth-child(even) {
            background-color: initial;
        }
    }

    td {
        padding: 7px;
    }
}

.hidden_mobile {
    @media(max-width: $sm) {
        display: none;
    }
}