.error {
    display: grid;
    place-items: center;
    height: 80vh;
}

.icon_wrap {
    background-color: #f7f8fa;
    padding: 1rem;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
}

.unit_sold_title {
    color: var(--color-purple);
}

.total_sales_title {
    color: var(--color-green);
}

.total_orders_title {
    color: var(--color-blue);
}
