$lg : 991px;

.createHeading {
    text-align: center;
}

.generatePasswordButton {
    margin-top: 3.5rem;

    @media(max-width: $lg) {
        margin-top: 0;
    }
}

.referralCode {
    margin-top: 5px !important;

    label {
        padding-left: 4px;
    }
}

.createCustomerSubmitButton {
    margin-top: 2rem;
}

.createCustomerSuccess {
    max-width: 500px;
    margin-inline: auto;
}


// tick mark animation
.animatedTick {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 60px;
        display: block;
    }

    .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 0;
    }

    .path.circle {
        -webkit-animation: dash 0.9s ease-in-out;
        animation: dash 0.9s ease-in-out;
    }

    .path.check {
        stroke-dashoffset: -100;
        -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
        animation: dash-check 0.9s 0.35s ease-in-out forwards;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }

    100% {
        stroke-dashoffset: 900;
    }
}

.actionButtons {
    display: flex;
    gap: 1rem;

    a {
        flex: 1 1 50%;
    }
}

.createCustomerContent {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
}