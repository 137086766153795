.barcode {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 430px;
    height: 260px;

    span {
        line-height: 1;
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
        padding: 0 0.4rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    svg {
        // width: 430px !important;
        // height: 225px !important;
    }
}
