.main {
    background-color: white;
    padding-inline: 2rem;
    padding-bottom: 1rem;
    border-radius: 1rem;
    margin-top: 2rem;
}

.tab_title {
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: -0.8rem;
}

.fetch_error {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    height: 80vh;

    img {
        height: 120px;
    }

    &_title {
        text-align: center;
    }
}

.primary_slider_view {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.action_btn {
    display: flex;
    align-items: center;
    gap: 8px;
}

.add_new_slider {
    display: flex;
    justify-content: center;
    align-items: center;
}
