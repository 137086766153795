.info {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;

    svg {
        flex-shrink: 0;
        margin-right: 1rem;
        // color: var(--color-nexa) !important;
        // fill: var(--color-nexa) !important;
    }

    span {
        font-size: 1.2rem;
    }
}
