$sm : 576px;

.product_input {
    border: 2px solid var(--color-green);
    padding: 0.5rem;
    border-radius: var(--radius);
    width: 100%;
}

.product_input_red {
    border: 2px solid var(--color-red);
    padding: 0.5rem;
    border-radius: var(--radius);
    width: 100%;
}

.h2_warehouse {
    text-align: center;
}

.create_stock_out_table {
    margin-top: 2rem;

    @media(max-width: $sm) {
        overflow-x: scroll;
    }
}