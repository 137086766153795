.create_blog_top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.featured_image {
    width: 100%;
    height: 25rem;
    object-fit: cover;
}