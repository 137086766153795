.merchantDetails {
    background-color: var(--color-white);
    padding: 2rem;
    border-radius: var(--radius);
    box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15);
}

.details {
    &_row {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        margin-bottom: 1rem;
    }

    &_title {
        font-weight: initial;
    }
}