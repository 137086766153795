$sm : 576px;

.orderItems,
.filter {
    background-color: var(--color-white);
    padding: 2rem;
    border-radius: var(--radius);
    box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15);
}

.filter {
    margin-bottom: 2rem;
}

.orderItemsTable {

    @media(max-width: $sm) {
        overflow-x: scroll;
    }
}

.actions_btn_wrapper {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}