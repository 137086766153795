:root {
  --color-white: #fff;
  --color-light: #fff;
  --color-dark: #141414;
  --color-primary: #171717;
  --color-warning: red;
  --color-grey: #f4f2f2;
  --color-grey-2: #e6e7e9;
  --color-grey-3: #8f9092;
  --color-grey-4: #353f66;
  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f8f9fb;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;
  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999;
  --color-green: #0fc157;
  --color-red: #ff544f;
  --color-blue: #738bff;
  --color-purple: #9e68ff;
  --color-hotpink: #ff49a0;
  --color-nexa: #fdc201;
  --shadow-primary: 5px 7px 23px 1px rgba(0, 0, 0, 0.11);
  --radius: 5px; }

[data-theme="dark"] {
  --color-white: #18191a;
  --color-light: #18191a;
  --color-dark: #fff;
  --color-primary: #fff;
  --color-warning: red;
  --color-grey: #252525;
  --color-grey-2: #e6e7e9;
  --color-grey-3: #8f9092;
  --color-grey-4: #353f66;
  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #2b2b2b;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;
  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999; }

* {
  margin: 0;
  padding: 0; }

*,
*::after,
a::before {
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  font-size: 62.5%; }

body {
  font-weight: 500;
  line-height: 1.6;
  color: var(--color-dark) !important;
  background-color: var(--color-grey) !important;
  background-size: cover;
  background-repeat: no-repeat; }

input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none; }

:link {
  text-decoration: none; }

a {
  color: var(--color-primary); }

::-webkit-scrollbar {
  width: 3px;
  height: 3px; }

/* Track */
::-webkit-scrollbar-track {
  background: var(--color-white); }

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-grey-light-2);
  transition: all 0.3s;
  border-radius: 10px;
  border: 2px solid var(--color-light);
  transition: all 0.5s; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-grey-dark-3);
  border: 2px solid var(--color-light);
  transition: all 0.5s; }

::-webkit-scrollbar-thumb:active {
  background: var(--color-primary); }

.main {
  display: flex;
  min-height: 100vh; }

/*>>Sidebar*/
.sidebar {
  width: 270px;
  min-height: 100%;
  height: 100vh;
  background-color: var(--color-light);
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;
  position: fixed;
  border-right: 2px solid var(--color-light);
  overflow-y: scroll;
  font-size: 1.4rem; }
  .sidebar__top {
    padding-bottom: 2rem; }
    .sidebar__top span {
      font-size: 2.5rem;
      font-weight: 500;
      color: var(--color-white);
      font-family: "Poppins", sans-serif; }
  .sidebar__middle ul {
    margin-top: 2rem; }
    .sidebar__middle ul li {
      list-style: none;
      display: flex;
      align-items: center;
      color: var(--color-grey-dark-3);
      border-radius: 5px;
      padding: 1.5rem 2rem; }
      .sidebar__middle ul li svg {
        color: var(--color-grey-3);
        margin-right: 2rem; }
      .sidebar__middle ul li p {
        margin-bottom: 0; }
  .sidebar__middle--active {
    background-color: var(--color-primary); }
    .sidebar__middle--active svg {
      color: var(--color-white) !important; }
    .sidebar__middle--active p {
      color: var(--color-white);
      font-weight: 600; }
  .sidebar__bottom {
    margin-top: auto;
    margin-bottom: 2rem;
    cursor: pointer; }
    .sidebar__bottom svg {
      cursor: pointer; }

@media (max-width: 576px) {
  .hidden_sm {
    display: none; } }

/*<<Sidebar*/
/*>>Main Content*/
.main-content {
  min-height: 100%;
  width: 100%;
  background-color: var(--color-grey);
  border-radius: 5px;
  margin-left: 270px; }
  @media (max-width: 576px) {
    .main-content {
      margin-left: 0; } }

header {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-white); }
  header form {
    display: flex;
    align-items: center; }
    header form button {
      margin-top: 0.2rem;
      z-index: 2;
      margin-left: -70px;
      border: none;
      background-color: var(--color-grey); }
    header form input {
      width: 250px;
      padding: 1rem;
      border-radius: 20px;
      z-index: 1;
      border: none;
      background-color: var(--color-grey);
      padding-left: 4rem; }
      header form input::placeholder {
        font-family: "Poppins", sans-serif; }

/*<< Main Content*/
.user-box {
  display: flex; }

.icon-text {
  display: flex;
  align-items: center; }
  .icon-text span {
    font-size: 1.2rem; }

.round-bg-icon {
  display: flex;
  width: 35px;
  height: 35px;
  background-color: var(--color-grey);
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
  cursor: pointer;
  transition: 0.3s all; }
  .round-bg-icon:hover {
    background-color: var(--color-primary); }
    .round-bg-icon:hover svg {
      color: #fff; }
  .round-bg-icon svg {
    color: var(--color-primary); }
  .round-bg-icon-box {
    display: flex; }

.card, .table-wrapper, .filter {
  background-color: var(--color-white);
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15); }
  .card__title {
    font-size: 2rem;
    margin-bottom: 0;
    color: var(--color-dark);
    font-weight: 700; }
  .card__sub-title {
    color: var(--color-grey-3); }

.cardxx {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: 0.3s all;
  padding: 10rem 0;
  color: var(--color-primary); }
  .cardxx:hover {
    background-color: var(--color-primary);
    color: white; }
  .cardxx span {
    margin-top: 4rem;
    font-weight: 600;
    font-size: 1.5rem;
    cursor: pointer; }

.the-title {
  display: flex;
  align-items: center;
  margin-bottom: 5rem;
  color: var(--color-primary);
  font-size: 1.5rem;
  background-color: var(--color-grey);
  width: fit-content;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  margin-left: 50%;
  transform: translateX(-50%); }
  .the-title svg {
    margin-right: 1rem; }

.total-bill {
  font-size: 3rem; }
  .total-bill svg {
    margin-right: 0.5rem; }

.label {
  margin-top: 2rem;
  margin-bottom: 0.4rem;
  display: block;
  font-size: 1.2rem; }

/* >> Buttons and Selects*/
.button-primary, .button-outline, .select-primary {
  background-color: var(--color-primary);
  border: none;
  color: var(--color-white);
  padding: 0.8rem 2rem;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 36px; }
  .button-primary svg, .button-outline svg, .select-primary svg {
    margin-right: 1rem; }

.button-outline {
  border: 1px solid var(--color-primary);
  background-color: var(--color-white);
  color: var(--color-primary); }

.button-underline {
  border: none;
  background-color: var(--color-white);
  border-bottom: 1px solid inherit;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.2rem 0; }
  .button-underline svg {
    margin-right: 1rem; }

select {
  -webkit-appearance: none;
  appearance: none; }

.select-primary select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none; }

.label {
  margin-top: 1rem; }

/* << Buttons */
/* >> Layout*/
.h-right {
  display: flex;
  justify-content: flex-end; }

.h-left {
  display: flex;
  justify-content: flex-start; }

.h-center {
  display: flex;
  justify-content: center; }

.hv-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.v-center, .filter__item__button {
  display: flex;
  align-items: center; }

.v-top {
  display: flex;
  align-items: flex-start; }

.v-bottom {
  display: flex;
  align-items: flex-end; }

.fw {
  width: 100%; }

.fc {
  display: flex;
  flex-direction: column; }

.f-sb {
  display: flex;
  justify-content: space-between; }

/* << Layout*/
/* >> Table*/
.td,
.th {
  padding: 1rem 1rem; }

.th {
  text-align: left; }

tr:nth-child(even) {
  background-color: var(--color-grey-light-2); }

th,
td {
  padding: 7px; }

th {
  white-space: nowrap; }

.td {
  margin: 1rem 0 !important; }

table {
  width: 100%;
  font-size: 1.2rem !important; }

.table-wrapper {
  padding: 0;
  border-radius: 5px;
  padding-bottom: 4rem; }

.table-header {
  display: flex;
  padding: 2rem 2rem 0 2rem; }

.table-details {
  width: 100%;
  padding: 2rem;
  border-radius: 5px; }

.sh__pagination__center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 2rem; }

/* << Table*/
/* >> Side Drawer*/
.side-drawer {
  background-color: var(--color-white);
  padding: 2rem;
  border-radius: 5px; }
  .side-drawer__header {
    margin-bottom: 2rem; }
    .side-drawer__header h1 {
      margin-bottom: 0;
      font-size: 1.8rem; }
    .side-drawer__header span {
      color: var(--color-grey-3);
      font-size: 1.2rem; }
    .side-drawer__header svg {
      cursor: pointer;
      transition: 0.3s all;
      margin-top: 0.4rem; }
      .side-drawer__header svg:hover {
        color: var(--color-warning); }

/* << Side Drawer*/
.hr {
  border: 1px solid var(--color-grey);
  margin: 0.5rem 0; }

.twi {
  display: flex;
  font-weight: 700;
  font-size: 1.7rem;
  margin-bottom: 2rem;
  color: var(--color-grey-4); }
  .twi svg {
    margin-right: 1rem; }

.hover-effect {
  cursor: pointer; }
  .hover-effect svg {
    transition: 0.3s all; }
    .hover-effect svg:hover {
      color: var(--color-primary) !important;
      size: 100px !important; }

.w-icon {
  display: flex;
  align-items: center;
  font-weight: 700;
  color: var(--color-primary);
  cursor: pointer; }
  .w-icon svg {
    margin-right: 0.5rem;
    color: var(--color-primary); }

.no-data {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .no-data img {
    width: 150px;
    margin-bottom: 2rem; }
  .no-data span {
    font-size: 2rem;
    color: var(--color-grey-3); }

.modal-box title {
  font-weight: 700;
  font-size: 2rem;
  display: block; }

.modal-box__sub-title {
  color: var(--color-grey-3);
  font-size: 1.3rem; }

/*>> Animations*/
@keyframes pulsate {
  0% {
    transform: scale(1);
    box-shadow: none; }
  50% {
    transform: scale(1.05); }
  100% {
    transform: scale(1);
    box-shadow: none; } }

/*<< Animations*/
/*>> Custom File Upload*/
.file-upload {
  background-color: var(--color-light);
  width: 100%;
  margin: 0 auto; }

.file-upload-btn {
  width: 100%;
  margin: 0;
  color: #fff;
  background: var(--color-primary);
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid #15824b;
  transition: all 0.2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700; }

.file-upload-btn:hover {
  background: #1aa059;
  color: #ffffff;
  transition: all 0.2s ease;
  cursor: pointer; }

.file-upload-btn:active {
  border: 0;
  transition: all 0.2s ease; }

.file-upload-content {
  display: none;
  text-align: center; }

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer; }

.image-upload-wrap {
  margin-top: 10px;
  border: 1px dashed var(--color-primary);
  position: relative; }

.image-dropping,
.image-upload-wrap:hover {
  background-color: var(--color-grey-light-2); }

.image-title-wrap {
  padding: 0 15px 15px 15px;
  color: #222; }

.drag-text {
  text-align: center; }

.drag-text h3 {
  font-size: 1.4rem;
  text-transform: uppercase;
  color: var(--color-dark);
  font-family: "Poppins", sans-serif;
  margin-top: 1rem;
  padding: 60px 0; }

.file-upload-image {
  max-height: 200px;
  max-width: 200px;
  margin: auto;
  padding: 20px; }

.remove-image {
  width: 200px;
  margin: 0;
  color: #fff;
  background: #cd4535;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid #b02818;
  transition: all 0.2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700; }

.remove-image:hover {
  background: #c13b2a;
  color: #ffffff;
  transition: all 0.2s ease;
  cursor: pointer; }

.remove-image:active {
  border: 0;
  transition: all 0.2s ease; }

.landing-button {
  border: none;
  background-color: var(--color-white);
  padding: 2.26rem 0;
  border-radius: 5px;
  cursor: pointer;
  color: var(--color-dark);
  transition: 0.3s all;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 30%;
  margin-bottom: 2rem; }
  .landing-button svg {
    margin-right: 1rem; }
  .landing-button:hover {
    color: var(--color-white);
    background-color: var(--color-primary); }

.landing-title {
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1rem;
  display: block;
  color: var(--color-grey-dark-2); }

.notification {
  margin-right: 4rem; }
  .notification svg {
    cursor: pointer; }

.filter {
  display: flex;
  margin-bottom: 2rem;
  gap: 1rem;
  flex-wrap: wrap; }
  .filter__item {
    width: fit-content;
    border: 1px solid var(--color-grey-light-2);
    border-radius: 5px;
    margin-right: 3rem; }
    .filter__item__name {
      padding-left: 1rem;
      padding-top: 1rem;
      display: block; }
    .filter__item__button {
      background-color: var(--color-primary);
      height: 100%;
      border: none;
      padding: 0 2rem;
      border-radius: 5px;
      cursor: pointer; }

.pagination__wrapper {
  margin-top: 3rem;
  margin-left: 0.5rem; }

@keyframes pulsate {
  0% {
    transform: scale(1);
    box-shadow: none; }
  50% {
    transform: scale(1.05);
    text-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.25); }
  100% {
    transform: scale(1);
    box-shadow: none; } }

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .loader h2 {
    line-height: 0;
    font-size: 3rem;
    font-weight: 800; }
  .loader span {
    font-size: 1.5rem; }

/* Spinner Circle Rotation */
.sp-circle, .spinner-white {
  border: 4px rgba(255, 255, 255, 0.25) solid;
  border-top: 4px white solid;
  border-radius: 50%;
  -webkit-animation: spCircRot 0.6s infinite linear;
  animation: spCircRot 0.6s infinite linear; }

.sp-circle-black, .spinner-black {
  border: 4px rgba(23, 23, 23, 0.25) solid;
  border-top: 4px #171717 solid;
  border-radius: 50%;
  -webkit-animation: spCircRot 0.6s infinite linear;
  animation: spCircRot 0.6s infinite linear; }

@-webkit-keyframes spCircRot {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg); } }

@keyframes spCircRot {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

/*>> Primary Card*/
.card-primary {
  background-color: var(--color-light);
  padding: 2rem;
  border-radius: var(--radius);
  height: 100%;
  max-height: calc(100vh - 130px);
  overflow-y: auto; }

/*<< Primary Card*/
.image-gallery {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; }
  .image-gallery__item {
    flex: 0 0 14%;
    padding: 3%;
    position: relative; }
    .image-gallery__item .icon {
      position: absolute;
      right: 10%;
      top: 10%;
      border: none;
      background: transparent;
      cursor: pointer; }
    .image-gallery__item img {
      width: 100%;
      display: block; }

.draftjs-editor-wrapper {
  border: 1px solid var(--color-dark);
  border-style: dashed;
  padding: 2rem; }

label {
  font-weight: 600;
  margin-bottom: 0.5rem;
  display: block; }

.editorClassName {
  min-height: 200px; }

.primary-hr {
  border: none;
  border-top: 1px solid var(--color-grey-light-3);
  width: 100%; }

.primary-link {
  font-weight: bold;
  color: var(--color-blue); }
  .primary-link:hover {
    color: var(--color-blue);
    text-decoration: underline; }

.ant-message-notice-content {
  padding: 2.5rem !important;
  background-color: #3f3f3f !important;
  color: white !important; }

/* >> Auth -  Login, Register*/
.auth {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 455px;
  margin-inline: auto; }
  @media (max-width: 460px) {
    .auth {
      padding: 0 2rem; } }

.login button {
  margin-top: 2rem;
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  font-size: 1.2rem;
  padding: 2rem 3rem; }

.login__header h1 {
  font-size: 1.6rem; }

.login__header span {
  color: var(--color-grey-dark-3); }

.login__reset-box {
  margin-top: 2rem; }
  .login__reset-box span {
    margin-top: 0.6rem;
    font-size: 1.2rem;
    color: var(--color-grey-dark-2); }

.login__reg-msg {
  margin-top: 2rem;
  display: block; }
  .login__reg-msg a {
    margin-left: 0.4rem;
    color: var(--color-primary); }

.login__otp-wrapper {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end; }
  .login__otp-wrapper button {
    transform: translateX(0%);
    margin-left: 0; }

/* << Auth -  Login, Register*/
/* >> Header*/
.user-box__sign {
  background-color: var(--color-grey);
  width: fit-content;
  height: 50px;
  width: 50px;
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 5px;
  margin-right: 1rem; }

.user-box__name h2 {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 0;
  color: var(--color-dark); }

.user-box__name span {
  font-size: 1.1rem;
  color: var(--color-grey-3); }

.header-navigation {
  display: flex;
  height: 100%; }
  .header-navigation__arrows {
    margin-right: 2rem; }
    .header-navigation__arrows svg {
      color: var(--color-grey-2);
      transition: 0.3s all;
      cursor: pointer; }
      .header-navigation__arrows svg:hover {
        color: var(--color-grey-3); }
  .header-navigation__breadcrumb {
    display: flex; }
    .header-navigation__breadcrumb span {
      color: var(--color-grey-3); }
    .header-navigation__breadcrumb p {
      margin-bottom: 0rem;
      margin-left: 0.3rem;
      font-weight: 600; }
  @media (max-width: 576px) {
    .header-navigation {
      display: none; } }

@media (max-width: 576px) {
  .header_search_form,
  .notification {
    display: none; } }

.header_mobile_menu {
  display: none; }
  @media (max-width: 576px) {
    .header_mobile_menu {
      display: flex;
      height: 100%;
      align-items: center; } }

/* << Header*/
/* >> Info Cadd */
.info-card {
  background-color: var(--color-white);
  padding: 2rem;
  border-radius: 5px; }
  .info-card__title {
    font-weight: 600;
    font-size: 1.5rem;
    color: var(--color-grey-3); }
  .info-card__data {
    font-size: 3rem;
    font-weight: 600; }
  .info-card__link {
    display: block;
    margin-top: 2rem;
    text-decoration: underline;
    text-decoration-style: dotted;
    color: var(--color-primary);
    cursor: pointer; }

.icon-box {
  background-color: var(--color-grey-light-2);
  width: fit-content;
  height: fit-content;
  width: 50px;
  height: 50px;
  border-radius: 5px; }

/* << Info Cadd */
/* >> Payment Box*/
.payment-box title {
  font-weight: 700;
  font-size: 2rem;
  display: block; }

.payment-box__sub-title {
  color: var(--color-grey-3);
  font-size: 1.3rem; }

/* << Payment Box*/
/*>> Rider Box*/
.rider-box {
  margin-top: 2rem; }
  .rider-box__active-orders-title {
    margin-bottom: 1rem;
    color: var(--color-primary);
    font-size: 1.3rem;
    display: block; }
  .rider-box p {
    font-size: 1.2rem;
    display: block;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 1rem;
    color: var(--color-grey-3); }

/*<< Rider Box*/
/*>> Map Marker*/
.marker img {
  height: 40px;
  background-color: white;
  border-radius: 100%;
  padding: 0.2rem; }

.marker__card {
  background-color: var(--color-white);
  width: fit-content;
  padding: 1rem;
  border-radius: 5px;
  margin-top: 0.5rem; }
  .marker__card title {
    display: block;
    white-space: pre;
    font-size: 1.5rem;
    font-weight: 500; }
  .marker__card span {
    color: var(--color-grey-3);
    margin-top: 0.5rem; }
  .marker__card__live-beacon {
    color: var(--color-success);
    animation: pulsate 1s infinite; }

/*<< Map Marker*/
.sh__viewAllProducts,
.sh__viewAllCatalogueProducts {
  background-color: var(--color-white);
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15);
  margin-top: 2rem; }

.sh__name__price,
.sh__category__selling,
.sh__sku__stock {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem; }

.sh__name {
  flex: 3;
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.sh__price {
  flex: 1;
  font-size: 1.6rem;
  margin-bottom: 0;
  text-align: right; }

.sh__category {
  flex: 1;
  font-size: 1.6rem;
  margin-bottom: 0;
  font-weight: 600; }

.sh__selling {
  flex: 1;
  font-size: 1.6rem;
  margin-bottom: 0;
  text-align: right; }

.sh__sku {
  flex: 1;
  font-size: 1.6rem;
  margin-bottom: 0;
  font-weight: 600; }

.sh__stock {
  flex: 1;
  font-size: 1.6rem;
  margin-bottom: 0;
  text-align: right; }

.sh__pagination__center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; }

.sh__edit__products {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem; }

.sh__edit__products-icon {
  width: 2rem;
  height: 2rem; }

.ant-card-body {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important; }

.sh__createCatalogue {
  background-color: var(--color-white);
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15);
  margin-top: 2rem; }

.sh__viewAllCataloguesButtons {
  background-color: var(--color-white);
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15);
  margin-top: 2rem; }

.sh__paymentCard {
  background-color: var(--color-white);
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; }

.sh__paymentCardLeftImage {
  width: 7rem;
  height: 7rem;
  margin-right: 2rem; }

.sh__paymentCardLeftContent {
  padding: 1rem; }

.sh__paymentCardLeftContentTitle {
  display: flex;
  gap: 10px; }

.sh__paymentCardLeftContentTitleHead {
  font-size: 1.7rem;
  font-weight: 500;
  margin: 0; }

.sh__paymentCardLeftContentTitlePercentage {
  padding: 3px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  border-radius: 5px; }
  .sh__paymentCardLeftContentTitlePercentage.profit {
    background-color: #f1f8f6; }
  .sh__paymentCardLeftContentTitlePercentage.loss {
    background-color: #ff8181; }
  .sh__paymentCardLeftContentTitlePercentage p {
    margin: 0; }

.sh__paymentCardLeftContentMonth {
  color: #c1c0c5; }

.sh__paymentCardLeftContentAmount {
  margin: 0;
  font-size: 2.5rem; }

.sh__paymentTable {
  background-color: var(--color-white);
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15);
  margin-top: 2rem; }

.sh__paymentTableTitle {
  text-align: center;
  font-size: 2.6rem; }

.sh__paymentAccounts {
  background-color: var(--color-white);
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15);
  margin-top: 2rem; }

.sh__paymentAccountsTitle {
  text-align: center;
  font-size: 2.6rem; }

.sh__paymentModalTitle {
  margin-bottom: 0;
  font-weight: 600; }

.sh__paymentModalSubTitle {
  color: #8e8e8f; }

.sh__setPaymentNumberForm {
  margin-top: 1rem; }

.sh__paymentAccountsCards {
  margin-top: 2rem; }

.sh__paymentAccountsCardsContent {
  padding: 1rem;
  background-color: #e6e6e9;
  border-radius: 4px;
  position: relative; }

.sh__deletePaymentMethod {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer; }

.sh__deletePaymentIcon {
  width: 2.3rem;
  height: 2.3rem; }

.sh__settings {
  background-color: var(--color-white);
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15); }

.sh__settingsTitle {
  text-align: left;
  font-weight: 700;
  padding-left: 1rem; }

.sh__settingsForm {
  padding: 1rem; }

.sh__categoryAddFormHead {
  font-weight: 700; }

.sh__categoryAddForm,
.sh__showAllCategories {
  background-color: var(--color-white);
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15); }

.sh__showAllCategories .th,
.sh__showAllCategories .td {
  padding: 10px; }

.sh__subCategoryAddForm,
.sh__showAllSubCategories {
  background-color: var(--color-white);
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15); }

.sh__showAllSubCategories .th,
.sh__showAllSubCategories .td {
  padding: 10px; }

.sh__subSubCategoryAddForm,
.sh__showAllSubSubCategories {
  background-color: var(--color-white);
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15); }

.sh__showAllSubSubCategories .th,
.sh__showAllSubSubCategories .td {
  padding: 10px; }

.sh__brandsAddForm,
.sh__showAllBrands {
  background-color: var(--color-white);
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15); }

.sh__showAllBrands .th,
.sh__showAllBrands .td {
  padding: 10px; }

.sh__specificationAddForm,
.sh__showPaginatedSpecifications {
  background-color: var(--color-white);
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15); }

.primary_h2 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: var(--color-dark);
  font-weight: 500; }

/** 1 **/
@keyframes spinHalf {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(360deg); } }

.pre path {
  transform-origin: center;
  transform-box: fill-box;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden; }

.load1 path:nth-of-type(1) {
  animation: 2s cubic-bezier(0.19, 1, 0.22, 1) 0.15s infinite spinHalf; }

.load1 path:nth-of-type(2) {
  animation: 2s cubic-bezier(0.19, 1, 0.22, 1) 0.3s infinite spinHalf; }

.load1 path:nth-of-type(3) {
  animation: 2s cubic-bezier(0.19, 1, 0.22, 1) 0.45s infinite spinHalf; }

.load1 path:nth-of-type(4) {
  animation: 2s cubic-bezier(0.19, 1, 0.22, 1) 0.6s infinite spinHalf; }

.load1 path:nth-of-type(5) {
  animation: 2s cubic-bezier(0.19, 1, 0.22, 1) 0.75s infinite spinHalf; }

.load1 path:nth-of-type(6) {
  animation: 2s cubic-bezier(0.19, 1, 0.22, 1) 0.9s infinite spinHalf; }

.load1 path:nth-of-type(7) {
  animation: 2s cubic-bezier(0.19, 1, 0.22, 1) 1.05s infinite spinHalf; }

.load1 path:nth-of-type(8) {
  animation: 2s cubic-bezier(0.19, 1, 0.22, 1) 1.2s infinite spinHalf; }

.sp, .spinner-white, .spinner-black {
  width: 28px;
  height: 28px;
  clear: both;
  margin: 20px auto; }

/* Spinner Circle Rotation */
.sp-circle, .spinner-white {
  border: 4px rgba(255, 255, 255, 0.25) solid;
  border-top: 4px white solid;
  border-radius: 50%;
  -webkit-animation: spCircRot 0.6s infinite linear;
  animation: spCircRot 0.6s infinite linear; }

.sp-circle-black, .spinner-black {
  border: 4px rgba(23, 23, 23, 0.25) solid;
  border-top: 4px #171717 solid;
  border-radius: 50%;
  -webkit-animation: spCircRot 0.6s infinite linear;
  animation: spCircRot 0.6s infinite linear; }

@-webkit-keyframes spCircRot {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg); } }

@keyframes spCircRot {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

button:disabled {
  opacity: 0.6;
  cursor: not-allowed; }

.create_transaction {
  background-color: var(--color-white);
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15); }

.create_transaction_checkbox {
  padding-top: 2rem; }

#specifications_refresh_btn_loading {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.p_notification {
  background-color: var(--color-white);
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15); }

.create_order {
  background-color: var(--color-white);
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 2px 1px 33px -17px rgba(0, 0, 0, 0.15); }

.hover-effect {
  cursor: pointer; }
  .hover-effect svg {
    transition: 0.3s all; }
    .hover-effect svg:hover {
      color: var(--color-red) !important;
      size: 200px !important; }

.product__table {
  overflow-x: scroll;
  width: calc(100vw - 364px);
  min-height: 60vh; }
  @media (max-width: 576px) {
    .product__table {
      width: 100%; } }

.flex_grow_full {
  flex-grow: 1; }

.print_invoice_container {
  font-weight: initial; }

.print_h_even {
  display: flex;
  justify-content: space-between; }

.print_invoice {
  width: 210mm;
  max-width: 210mm;
  height: auto;
  padding: 2rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column; }
  @media print {
    .print_invoice {
      display: table-header-group;
      page-break-inside: avoid;
      page-break-after: auto; } }

.print_logo {
  width: 120px; }

.print_nexa_title {
  font-size: 2.6rem;
  font-weight: 600; }

.print_tax_invoice {
  font-size: 1.6rem;
  font-weight: 500; }

.print_table {
  margin-top: 2rem;
  width: 100%; }
  @media print {
    .print_table {
      page-break-after: auto; } }
  @media print {
    .print_table thead {
      display: table-header-group; } }
  @media print {
    .print_table tr {
      page-break-inside: avoid;
      page-break-after: auto; } }
  .print_table tr:nth-child(even) {
    background-color: initial !important; }
  .print_table th {
    text-align: left;
    border-bottom: 1.2px solid var(--color-grey-light-4);
    border-top: 1px solid var(--color-grey-light-4);
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .print_table th,
  .print_table td {
    padding: 0.7rem; }
    @media print {
      .print_table th,
      .print_table td {
        padding: 0 1rem;
        page-break-inside: avoid;
        page-break-after: auto; } }

.print_item_name {
  width: 300px; }

.print_s_title {
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
  display: block; }

.print_footer {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.print_site_link {
  font-size: 1.6rem;
  color: var(--color-nexa);
  font-weight: 600; }

.print_hashtag {
  color: var(--color-nexa);
  font-size: 2.4rem;
  font-weight: 600; }

.print_social {
  display: flex;
  align-items: center;
  margin-right: 2rem; }
  .print_social svg {
    margin-right: 0.5rem; }

.print_hr {
  border-top: none;
  width: 100%;
  border-bottom: 1px solid var(--color-grey-light-3) !important;
  align-self: center;
  margin: 2rem auto;
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

@media (max-width: 576px) {
  .order_table,
  .brands_table {
    overflow-x: scroll; } }
