.order_id {
    // font-weight: bold;
    display: block;
    font-size: 1.6rem;
}

.top_info {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.addr {
    text-transform: capitalize !important;
}

.watermark {
    position: absolute;
    margin-top: 60%;
    margin-left: 50%;
    width: 60%;
    transform: translate(-50%, -50%);
    opacity: .05;
}