.label {
    padding-left: 4px;
}

.order_details_card {
    border: 1px solid var(--color-grey-3);
    border-radius: var(--radius);

    table {
        overflow: hidden;
    }

    thead {
        border-bottom: 1px solid var(--color-grey-light-3);
    }

    th,
    td {
        text-align: center;
        font-size: 1.5rem;
    }
}

.create_order_divider {
    margin: 0.5rem !important;
    border-color: var(--color-primary) !important;
}

.finalCalculation {
    text-align: right;

    p {
        margin: 0;
        font-size: 2rem;

        &:nth-child(1) {
            margin-top: -1.5rem;
        }

        &:nth-child(3) {
            margin-bottom: 1rem;
        }
    }
}

.option_image {
    width: 36px;
    height: 36px;
    object-fit: cover;
    object-position: top;
    margin-right: 1rem;
}