$sm : 576px;

.top_bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
}

.stock_in_filter {
    div {

        @media(max-width: $sm) {
            flex: 1 1 auto;
        }
    }
}

.stock_in_table {
    margin-top: 2rem;

    @media(max-width: $sm) {
        overflow-x: scroll;
    }
}