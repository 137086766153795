.card {
    background-color: var(--color-light);
    padding: 2rem;
    display: flex;
    flex-direction: column;

    form {
        max-width: 300px;
    }

    input {
        padding: 2rem;
        border: 1px solid var(--color-grey-light-3);
        border-radius: var(--radius);
        width: 100%;
        color: var(--color-dark);
    }

    button {
        padding: 2rem;
        border: 1px solid var(--color-grey-light-3);
        border-radius: var(--radius);
        background-color: var(--color-green);
        width: 100%;
        color: var(--color-light);
        font-weight: bold;
        margin-top: 2rem;
        cursor: pointer;
    }

    label {
        margin-top: 2rem;
    }
}

.commission {
    font-weight: bold;
    font-size: 2rem !important;
    margin-top: 2rem;
    color: var(--color-primary);

}

.result {
    display: flex;
    flex-direction: column;
    span {
        font-size: 1.6rem;
    }
}