.tbody {
    width: 100%;
    margin-bottom: 1.6rem;
}

.button {
    margin: 1rem 0;
}

.top_action {
    display: flex;
    align-items: center;
    gap: 1rem;
}